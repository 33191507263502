@learning-objective-card-left-right-spacing: @ax-spacing-m;
@learning-objective-card-left-right-spacing-mobile: .8rem;
@learning-objective-card-graphic-size-mobile: 7.5rem;

// TODO: confirm if design doesn't want to reuse existing cert/task/etc colors, and/or if the following colors
// should be added to variables.less file. For now, leaving here to match mockups.

@learning-objective-card-interactive-hover-title-color: @ax-color-grey-60;

@learning-objective-card-icon-topic-color: @ax-color-training;
@learning-objective-card-icon-certification-color: @ax-color-certification;
@learning-objective-card-icon-task-color: @ax-task-color-60;
@learning-objective-card-icon-event-color: @ax-color-event;
@learning-objective-card-icon-evaluation-color: @ax-assessment-color-90;

@learning-objective-card-lock-icon-color: @ax-color-grey-60;

@learning-objective-card-overdue-color: #CA0D16;

@learning-objective-card-red-border-width: 0.5rem;

.learning-objective-card-mobile-styles(@rules) {
  .screen-md-portrait-max({
    @rules();
  });
}

.learning-objective-card {
  .align-items(center);
  .display-flex();
  padding: @ax-spacing-m;

  &&--interactive {
    cursor: pointer;
  }

  &&--overdue {
    .border-radius(@ax-border-radius-m);
    border-left: @learning-objective-card-red-border-width solid @learning-objective-card-overdue-color;
    padding-left: @ax-spacing-m - @learning-objective-card-red-border-width;

    .learning-objectives__item:not(:last-child) & {
      border-bottom-left-radius: 0;
    }

    .learning-objectives__item:not(:first-child) & {
      border-top-left-radius: 0;
    }

    .learning-objective-card-mobile-styles({
      padding-left: @learning-objective-card-left-right-spacing-mobile - @learning-objective-card-red-border-width;
    });
}

.learning-objective-card-mobile-styles({
  display: block;
  padding: @learning-objective-card-left-right-spacing-mobile;
  position: relative;
});
}

.learning-objective-card__graphic-wrapper {
  .flex-grow(0);

  .learning-objective-card-mobile-styles({
    position: absolute;
    top: @learning-objective-card-left-right-spacing-mobile;
    left: @learning-objective-card-left-right-spacing-mobile;
  });
}

.learning-objective-card__graphic {
  .align-items(center);
  .display-flex();
  .justify-content(center);
  margin-right: @learning-objective-card-left-right-spacing;
  .size(9.6rem);

  .learning-objective-card-mobile-styles({
    .size(@learning-objective-card-graphic-size-mobile);
    margin-right: @learning-objective-card-left-right-spacing-mobile;
  });
}

.learning-objective-card__graphic-icon {
  font-size: 6rem;

  &.icon-topic {
    color: @learning-objective-card-icon-topic-color;
  }

  &.icon-task {
    color: @learning-objective-card-icon-task-color;
  }

  &.icon-certifications {
    color: @learning-objective-card-icon-certification-color;
  }

  &.icon-events {
    color: @learning-objective-card-icon-event-color;
  }

  ;

  &.icon-evaluation {
    color: @learning-objective-card-icon-evaluation-color;
  }
}

.learning-objective-card__graphic-thumbnail {
  background-position: center;
  background-size: cover;
  .border-radius(@ax-border-radius-m);
  .size(100%);
}

.learning-objective-card__content-wrapper {
  min-width: 0;
  .flex-grow(1);

  .learning-objective-card-mobile-styles({
    margin-left: @learning-objective-card-graphic-size-mobile + @learning-objective-card-left-right-spacing-mobile;
  });
}

.learning-objective-card__title-tag-wrapper {
  .learning-objective-card-mobile-styles({
    min-height: @learning-objective-card-graphic-size-mobile;
  });
}

.learning-objective-card__title {
  font-size: @ax-font-size-s;
  margin-bottom: 1rem;

  .learning-objective-card--interactive:hover & {
    color: @learning-objective-card-interactive-hover-title-color;
  }

  .learning-objective-card-mobile-styles({
    font-size: 1.6rem;
    margin-bottom: @ax-spacing-xs;
  });
}

.learning-objective-card__parent-title {
  font-size: @ax-font-size-xs;
  color: @ax-color-grey-60;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.learning-objective-card__tag-wrapper {
  line-height: 3rem;
  margin-top: -.5rem;
  margin-bottom: 1rem;
}

.learning-objective-card__cert-status--warning {
  color: @learning-objective-card-overdue-color;
}

.learning-objective-card__meta-data,
.assessment-card__meta-data {
  color: @ax-color-grey-60;
  font-size: 1.4rem;
  margin: 0;
  padding: 0;

  span {
    font-size: 1.4rem;
    margin-right: 1.6rem;

    .ax-breakpoint-m({
      white-space: nowrap;
    });

  &:last-child {
    margin-right: 0;
  }
}

.learning-objective-card-mobile-styles({
  font-size: 1.2rem;
});
}

.learning-objective-card__meta-action-wrapper {
  align-items: end;

  .learning-objective-card-mobile-styles({
    .display-flex();
    align-items: start;
    .flex-direction(column);
    margin-left: -@learning-objective-card-graphic-size-mobile;
    margin-right: @ax-spacing-s;
    margin-bottom: @ax-spacing-s;
  });
}

.learning-objective-card__meta-data-wrapper {
  .display-flex();

  .learning-objective-card-mobile-styles({
    width: 100%;
    .flex-grow(1);
    margin-right: @learning-objective-card-left-right-spacing;
  });
}

.learning-objective-card__meta-data {
  .display-flex();
  .flex-direction(row);
  width: 100%;
  .flex-grow(1);
}

.learning-objective-card__meta-data--locked {
  .align-self(flex-end);
  margin-left: auto;

  span {
    font-size: @ax-font-size-xl;
  }
}

.learning-objective-card__action-wrapper {
  .flex-grow(0);

  button {
    min-width: 0;
    padding-top: @ax-spacing-s;
    padding-bottom: @ax-spacing-s;
    margin-left: @ax-spacing-xs;
  }

  button:not(.ax-button--icon-left) {
    line-height: normal;
  }

  .icon-lock {
    color: @learning-objective-card-lock-icon-color;
    font-size: 2.4rem;
  }

  .learning-objective-card-mobile-styles({
    display: none;
    .flex-grow(1);

    button {
      width: 100%;
      margin-left: 0;
      margin-top: @ax-spacing-s;
    }
  });

&&--mobile {
  display: none;

  .learning-objective-card-mobile-styles({
    display: block;
    width: 100%;
  });
}
}


/*
  Select Assessment Cards
*/

.overview-container {
  .ax-breakpoint-m({
    margin-top: 3.2rem;
  });
}

.overview-actions {
  .topic-search {
    max-width: none;
  }

  .select-instruction {
    font-size: @ax-font-size-s;
  }

  .screen-md-landscape-min({
    .topic-search {
      margin-bottom: @ax-spacing-xxl;
    }

    .select-instruction {
      font-size: @ax-font-size-l;
    }
  });

.ax-breakpoint-m({
  .topic-search {
    max-width: 36rem;
  }
});
}

.overview-sidebar .progress-card {

  h2,
  h3,
  .progress-value {
    margin-bottom: @ax-spacing-s;

    .ax-breakpoint-m({
      margin-bottom: @ax-spacing-m;
    });
}

h2,
.progress-value {
  font-size: @ax-font-size-l;

  .ax-breakpoint-m({
    font-size: @ax-font-size-xl;
  });
}
}

.overview-assessments {
  .card {
    margin: @ax-spacing-l 0;
  }

  .assessment-card__meta-data {
    flex-wrap: wrap;
    .display-flex();
    .align-items(center);

    .topic-status {
      margin-right: @ax-spacing-m;
      .display-flex();
      .align-items(center);

      .icon-ok_circle {
        color: @ax-color-green-60;
      }

      .icon-remove_circle {
        color: @ax-color-red-60;
      }

      .topic-status--failed {
        font-weight: @ax-font-weight-medium;
      }

      .topic-status__icon {
        font-size: 2rem;
        margin-right: @ax-spacing-s;
      }
    }

    .reward-points__points-earned {
      font-weight: @ax-font-weight-medium;
    }
  }

  .learning-objective-card__action button {
    white-space: nowrap;

    &:focus {
      .ax-focus-keyboard();
    }
  }

  .screen-md-landscape-min({
    .learning-objective-card {
      padding: @ax-spacing-l @ax-spacing-m;

      .learning-objective-card__action {
        .learning-objective-card-mobile-styles({
          width: 100%;
          .display-flex();
        });

      .ax-button {
        margin-right: @ax-spacing-s;
      }

      .icon-lock {
        font-size: 3.2rem;
        margin-right: @ax-spacing-l;
      }
    }

    .learning-objective-card__graphic {
      margin-left: @ax-spacing-s;
      margin-right: @ax-spacing-l;
    }

    .learning-objective-card__tag-wrapper {
      margin-bottom: 2.8rem;
    }

    .learning-objective-card__title {
      font-size: @ax-font-size-l;
      font-weight: @ax-font-weight-medium;
      margin-bottom: @ax-spacing-s;
      margin-top: 1.2rem;
    }
  }
});
}
